<template>
  <div>

    <b-card>
      <b-overlay :show="formShow" rounded="sm" no-fade>

<validation-observer ref="invoiceRules">
  <b-form @submit.prevent>

         
              <span
                @click="activeTab = 'billable'"
                :class="{
                  activeMenu: activeTab === 'billable',
                  passiveMenu: activeTab !== 'billable',
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Billable Hours
              </span>

              <span
                @click="activeTab = 'non-billable'"
                :class="{
                  activeMenu: activeTab === 'non-billable',
                  passiveMenu: activeTab !== 'non-billable',
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Non-Billable Hours
              </span>
         
              
              <div v-if="activeTab == 'billable'">
                <b-card>
                  <b-row>
                    <!-- Col: Invoice To -->

                    <b-col cols="12" xl="2">
                      <h6 class="mb-2">
                        Start Date
                      </h6>

                      <!-- Select Client -->
                      <validation-provider name="Date" #default="{ errors }" rules="required">
                            <flat-pickr v-model="startDate" title="Date" style="width:100%; background-color:white"  :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>

                    
                    </b-col>

                    <b-col cols="12" xl="2">
                      <h6 class="mb-2">
                        End Date
                      </h6>
                      <validation-provider name="Due Date" #default="{ errors }" rules="required">
                            <flat-pickr v-model="endDate" style="width:100%;  background-color:white"  :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                    </b-col>


                    <b-col cols="12" xl="4">
                      <div class="d-flex mt-2">
                        <div class="mr-2 mt-2">
                      
                      <b-form-checkbox v-model="isNavMenuHidden" name="is-vertical-menu-collapsed" class="mr-0" inline >Collapse Menu</b-form-checkbox>
                      </div>
                   
                      </div>
                
                  

                  

                    </b-col>

                    
            

                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                      <b-button variant="primary" @click="exportExcel" class="mr-1">
                      Export
                      </b-button>
                    
                    </b-col>



                    
                  </b-row>
                  <b-row style="overflow-y:scroll" class="mt-3">

                

                        <b-table hover :items="invoiceData" :fields="fields" :small="true" thead-class="green-bg text-white" show-empty
                  empty-text="No matching records found">
                          <template v-for="(field, index) in fields"  v-slot:[`cell(${field.key})`]="{ item }">
                         
                            <div >

                              <span  v-b-tooltip.hover.v-secondary :title="item[field.key]">  {{ typeof item[field.key] === 'string' ? item[field.key].substring(0, 21) : field.key == 'Rate' || field.key == 'Cost' ? formatPrice(item[field.key] !== "" ? item[field.key] : 0,2) : item[field.key] !== "" ? item[field.key] : 'N/A' }}</span>

                            </div>
                        </template>
                      

                        </b-table>

                      



                      
                    

                    </b-row>
                    

                
                  
            


            
              
                </b-card>
              </div>

      <div v-if="activeTab == 'non-billable'">
      <b-card>
        <b-row>
          <!-- Col: Invoice To -->

          <b-col cols="12" xl="2">
            <h6 class="mb-2">
              Start Date
            </h6>

            <!-- Select Client -->
            <validation-provider name="Date" #default="{ errors }" rules="required">
                  <flat-pickr v-model="startDate" title="Date" style="width:100%; background-color:white"  :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

           
          </b-col>

          <b-col cols="12" xl="2">
            <h6 class="mb-2">
              End Date
            </h6>
            <validation-provider name="Due Date" #default="{ errors }" rules="required">
                  <flat-pickr v-model="endDate" style="width:100%;  background-color:white"  :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
          </b-col>


          <b-col cols="12" xl="4">
            <div class="d-flex mt-2">
              <div class="mr-2 mt-2">
            
            <b-form-checkbox v-model="isNavMenuHidden" name="is-vertical-menu-collapsed" class="mr-0" inline >Collapse Menu</b-form-checkbox>
            </div>
           
            </div>
      
         

        

          </b-col>

          
  

          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <b-button variant="primary" @click="exportExcel" class="mr-1">
             Export
            </b-button>
         
          </b-col>



          
        </b-row>
        <b-row style="overflow-y:scroll" class="mt-3">

       

              <b-table hover :items="invoiceData" :fields="externalFields" :small="true" thead-class="green-bg text-white" show-empty
               empty-text="No matching records found">
                <template v-for="(field, index) in externalFields"  v-slot:[`cell(${field.key})`]="{ item }">
                  <div>
                    <span v-if="(item[field.key]?item[field.key].length:0) <= (index!=10?15:5)">{{ item[field.key] !== "" ? item[field.key] : 'N/A' }} </span>

                    <span v-else v-b-tooltip.hover.v-secondary :title="item[field.key]">  {{ typeof item[field.key] === 'string' ? item[field.key].substring(0, (index!=10?15:5)) + '..' : item[field.key] !== "" ? item[field.key] : 'N/A' }}</span>

                  </div>
              </template>
            

              </b-table>

           



            
          

          </b-row>
          

       
        
  


   
     
      </b-card>
      </div>

          

       
        
  


   
     

     
     
  </b-form>
</validation-observer>

</b-overlay>

</b-card>

  </div>


</template>

<script>
import QuoteLogo from '@core/layouts/components/QuoteLogo.vue';
import { ref, onUnmounted } from '@vue/composition-api';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,VBTooltip } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import billingStoreModule from './billingStoreModule';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Cleave from 'vue-cleave-component';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    QuoteLogo,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    VBTooltip
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  setup() {
    const {
      isNavMenuHidden,
    } = useAppCustomizer();
    const BILLING_STORE_MODULE_NAME = 'billing';
    
    // Register module
    if (!store.hasModule(BILLING_STORE_MODULE_NAME)) store.registerModule(BILLING_STORE_MODULE_NAME, billingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BILLING_STORE_MODULE_NAME)) store.unregisterModule(BILLING_STORE_MODULE_NAME);
    });

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer'];

    return {
      paymentMethods,
      isNavMenuHidden
    };
  },

  data() {
   
    let currentDate = new Date();
    // Get the start date of last month
    let startDateOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    // Get the end date of last month
    let endDateOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    // Format the dates to Y-m-d format
    let formattedStartDateOfLastMonth = startDateOfLastMonth.getFullYear() + '-' + ('0' + (startDateOfLastMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + startDateOfLastMonth.getDate()).slice(-2);
    let formattedEndDateOfLastMonth = endDateOfLastMonth.getFullYear() + '-' + ('0' + (endDateOfLastMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + endDateOfLastMonth.getDate()).slice(-2);


    return {
      startDate:formattedStartDateOfLastMonth,
      endDate:formattedEndDateOfLastMonth,
      activeTab : 'billable',
      currentRoute:null,
      editingCell: null,
      editingItem :null,
      poData: null,
      changed: false,
      settingURL: store.state.app.settingURL,
      filter: null,
      filterOn: [],
      poNumbers: [],
      currencies: [],
      inputOptions: {
        number: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: 'none',
          delimiter: 'none',
          numeralIntegerScale: 3,
        },

        price: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: '.',
          delimiter: ',',
          numeralIntegerScale: 9,
        },

        phone: {
          phone: true,
        },
      },

      perPage: localStorage.getItem('customerShow') ?? 10,
      itemFormBlankItem: {
        cost: null,
        qty: null,
        description: null,
        inventoryId: null,
        vat: 20,
        main: false,
      },
      invoiceData: [],
      required,
      email,
      dropCustomers: [],
      dropPoNumbers: [],
      timesheets: [],
      quotedHours: [],

      quoted: 0,
      current: 0,

      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      formShow: false,

      statusType: null,
      status: null,
      fields: [
        { key: 'Project', label: 'Project', sortable: false, class: 'text-left', thStyle: 'width:5%;min-width:25px;', },
        { key: 'Employee', label: 'Employee', sortable: false, class: 'text-center', thStyle: 'width:10%;min-width:50px;', },
        { key: 'Description',label: 'Description', sortable: false, class: 'text-center', thStyle: 'width:10%;min-width:50px;',  },
        { key: 'Hours',label: 'Hours', sortable: false, class: 'text-center', thStyle: 'width:5%;min-width:50px;',  },
        { key: 'Rate',label: 'Rate', sortable: false, class: 'text-center', thStyle: 'width:5%;min-width:50px;',  },
        { key: 'Cost',label: 'Cost', sortable: false, class: 'text-center', thStyle: 'width:5%;min-width:50px;',  },
        { key: 'Date',label: 'Date', sortable: false, class: 'text-center', thStyle: 'width:5%;min-width:50px;',  },
        { key: 'JobDescription',label: 'Job Description', sortable: false, class: 'text-center', thStyle: 'width:10%;min-width:50px;',  },
        { key: 'Tag',label: 'Tag', sortable: true, class: 'text-center', thStyle: 'width:10%;min-width:50px;',  },
        { key: 'Requester',label: 'Requester', sortable: true, class: 'text-center', thStyle: 'width:10%;min-width:150px;', },
        { key: 'Account',label: 'Account', sortable: false, class: 'text-center', thStyle: 'width:25%;min-width:150px;',  },
       

 

        ],

   

        externalFields:[
      { key: 'Employee', label: 'Employee', sortable: false, class: 'text-left', thStyle: 'width:30%;min-width:120px;', },
      { key: 'Type', label: 'Type', sortable: false, class: 'text-center', thStyle: 'width:10%;min-width:120px;', },
        { key: 'Hours',label: 'Hours', sortable: true, class: 'text-center', thStyle: 'width:10%;min-width:120px;',  },
        { key: 'Description',label: 'Description', sortable: true, class: 'text-center', thStyle: 'width:30%;min-width:120px;', },
        { key: 'Date',label: 'Date', sortable: false, class: 'text-center', thStyle: 'width:20%;min-width:120px;',  },
        
 

        ],

  

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
      required,
      email,
    };
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  // computed: {
  //   totalTax() {
  //     var result = 0;

  //     this.invoiceData.items.forEach((element) => {
  //       result = result;
  //     });
  //     return result ? result : 0;
  //   },

  //   total() {
  //     var result = 0;
  //     this.invoiceData.items.forEach((element) => {
  //       result = result + element.cost * element.qty;
  //     });

  //     return result ? result : 0;
  //   },
  // },

  watch: {
    '$route'(to, from) {
        if (to != null || to != 'None') {
          this.currentRoute = router.currentRoute.name;
          this.getInvoiceData();
        }
      },

      'activeTab'() {
          this.getInvoiceData();
      },

      'startDate'(){
        if(this.startDate != null){
          this.getInvoiceData();
        }
      },
      'endDate'(){
        if(this.endDate != null){
          this.getInvoiceData();
        }
      }

  },

  methods: {

    exportExcel() {
      this.loading = true;
      store
        .dispatch("billing/exportEmployeeHours", {data:this.invoiceData,type:this.activeTab})
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.endDate + this.activeTab + ' ' + "hours.xlsx");
          document.body.appendChild(link);
          link.click();
          this.loading = false;
;

        })
        .catch((error) => {
          console.log(error);
        });
    },




    startEditing(item,cell) {
      // item.editing=true;
      this.editingItem = item;
        this.editingCell = cell;
        console.log(cell)

  


    },
    endEditing(item,cell) {
      if(this.activeTab == 'billable'){
        let chargeFields = ['Tub', 'Org','Object','Fund','Activity','Subactivity','Root']

        if(chargeFields.includes(cell) && this.editingItem!=null){
        let chargeNew = item.Tub +  item.Org +  item.Object +  item.Fund +  item.Activity +  item.Subactivity +  item.Root
          store
            .dispatch('billing/updateChargeString', {id:item.Project,chargeNew:chargeNew})
            .then((response) => {

              this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ Records have been saved successfully',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });
              
            })
            .catch((error) => {
              console.log(error);
            });
            this.getInvoiceData();


       

      }
      

      }
      else{

        if(this.editingItem!=null){
        let chargeNew = item.PoNumber
          store
            .dispatch('billing/updateChargeString', {id:item.Project,chargeNew:chargeNew})
            .then((response) => {

              this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ Records have been saved successfully',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });
              
            })
            .catch((error) => {
              console.log(error);
            });
            this.getInvoiceData();


       

      }
        
      }
   
      this.editingItem = null;

        this.editingCell = null;
        // Perform any necessary actions when editing ends, such as saving the data
    },

    formatPrice(value) {
      if (value != null) {
        // let val = (value / 1).toFixed(2).replace(',', '.');
        // return val;
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      }
    },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden';
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      //this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.invoiceData.changed = true;
    },


    getInvoiceData() {

      this.formShow=true;
     
      store
        .dispatch('billing/getEmployeeHours', {type:this.activeTab,startDate:this.startDate,endDate:this.endDate})
        .then((response) => {
          this.invoiceData = response.data.timesheetData;
          this.formShow=false;

        })
        .catch((error) => {
          console.log(error);
          this.formShow=false;

        });


   
    },

    


    
   
    

   



    



  
  },

  mounted() {
    this.currentRoute = router.currentRoute.name;
    this.getInvoiceData();
  },

  destroyed(){
    this.isNavMenuHidden = false;
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.cardClass {
  padding: 1.25rem;
  margin-bottom: 15%;
}


</style>

<!-- <style lang="css" scoped>

/* ::v-deep/ .table > tbody > tr > td { */
  /deep/ .table > tbody > tr > td {
    font-size: 10px !important;
  }


</style> -->


