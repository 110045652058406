var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.formShow,"rounded":"sm","no-fade":""}},[_c('validation-observer',{ref:"invoiceRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('span',{class:{
                  activeMenu: _vm.activeTab === 'billable',
                  passiveMenu: _vm.activeTab !== 'billable',
                },on:{"click":function($event){_vm.activeTab = 'billable'}}},[_c('i',{staticClass:"fa-solid fa-table-columns mr-25 mb-3 "}),_vm._v(" Billable Hours ")]),_c('span',{class:{
                  activeMenu: _vm.activeTab === 'non-billable',
                  passiveMenu: _vm.activeTab !== 'non-billable',
                },on:{"click":function($event){_vm.activeTab = 'non-billable'}}},[_c('i',{staticClass:"fa-solid fa-table-columns mr-25 mb-3 "}),_vm._v(" Non-Billable Hours ")]),(_vm.activeTab == 'billable')?_c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Start Date ")]),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control invoice-edit-input",staticStyle:{"width":"100%","background-color":"white"},attrs:{"title":"Date","config":{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,16673082)})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" End Date ")]),_c('validation-provider',{attrs:{"name":"Due Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control invoice-edit-input",staticStyle:{"width":"100%","background-color":"white"},attrs:{"config":{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3338256087)})],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"mr-2 mt-2"},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"name":"is-vertical-menu-collapsed","inline":""},model:{value:(_vm.isNavMenuHidden),callback:function ($$v) {_vm.isNavMenuHidden=$$v},expression:"isNavMenuHidden"}},[_vm._v("Collapse Menu")])],1)])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.exportExcel}},[_vm._v(" Export ")])],1)],1),_c('b-row',{staticClass:"mt-3",staticStyle:{"overflow-y":"scroll"}},[_c('b-table',{attrs:{"hover":"","items":_vm.invoiceData,"fields":_vm.fields,"small":true,"thead-class":"green-bg text-white","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([_vm._l((_vm.fields),function(field,index){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],attrs:{"title":item[field.key]}},[_vm._v(" "+_vm._s(typeof item[field.key] === 'string' ? item[field.key].substring(0, 21) : field.key == 'Rate' || field.key == 'Cost' ? _vm.formatPrice(item[field.key] !== "" ? item[field.key] : 0,2) : item[field.key] !== "" ? item[field.key] : 'N/A'))])])]}}})],null,true)})],1)],1)],1):_vm._e(),(_vm.activeTab == 'non-billable')?_c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Start Date ")]),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control invoice-edit-input",staticStyle:{"width":"100%","background-color":"white"},attrs:{"title":"Date","config":{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,16673082)})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" End Date ")]),_c('validation-provider',{attrs:{"name":"Due Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control invoice-edit-input",staticStyle:{"width":"100%","background-color":"white"},attrs:{"config":{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3338256087)})],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"mr-2 mt-2"},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"name":"is-vertical-menu-collapsed","inline":""},model:{value:(_vm.isNavMenuHidden),callback:function ($$v) {_vm.isNavMenuHidden=$$v},expression:"isNavMenuHidden"}},[_vm._v("Collapse Menu")])],1)])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.exportExcel}},[_vm._v(" Export ")])],1)],1),_c('b-row',{staticClass:"mt-3",staticStyle:{"overflow-y":"scroll"}},[_c('b-table',{attrs:{"hover":"","items":_vm.invoiceData,"fields":_vm.externalFields,"small":true,"thead-class":"green-bg text-white","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([_vm._l((_vm.externalFields),function(field,index){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
                var item = ref.item;
return [_c('div',[((item[field.key]?item[field.key].length:0) <= (index!=10?15:5))?_c('span',[_vm._v(_vm._s(item[field.key] !== "" ? item[field.key] : 'N/A')+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],attrs:{"title":item[field.key]}},[_vm._v(" "+_vm._s(typeof item[field.key] === 'string' ? item[field.key].substring(0, (index!=10?15:5)) + '..' : item[field.key] !== "" ? item[field.key] : 'N/A'))])])]}}})],null,true)})],1)],1)],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }